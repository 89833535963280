.gba_head {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #0071C4;
    height: 70px;
    z-index: 99;
}

.body_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home_banner {
    width: 100%;
    height: 400px;
}

.home_banner_1 {
    background-image: url(../img/banner.png);
    background-size: 100% 100%;
    height: 400px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
}

.banner_title {
    color: #fff;
    font-size: 42px;
}

.banner_text {
    width: 1200px;
    font-size: 22px;
    line-height: 36px;
    color: #fff;
}

.login_head {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-right: 20px;
    cursor: pointer;
}

.login_active {
    color: #0171C5;
    border-bottom: 1px solid #0171C5;
}

.login_way {
    margin: 18px 0;
}

.login_forget_pas {
    width: 100%;
    text-align: right;
    margin-bottom: 20px;
}

.login_forget_pas a {
    text-decoration: underline;
    color: #0171C5;
    font-size: 14px;
}

.login_btn {
    width: 100%;
    height: 40px;
    background-color: #0171C5;
    border-radius: 2px;
}

.login_register_text {
    text-align: center;
    margin-top: 14px;
}

.login_register_text a {
    color: #0171C5;
    font-size: 16px;
    text-decoration: underline;
}

.login_register {
    margin-top: 20px;
}

.login_clause {
    text-align: center;
    font-size: 14px;
}

.login_clause_text {
    color: #666;
    font-size: 12px;
    margin-top: 13px;
    margin-left: -28px;
    width: 469px;
    margin-bottom: 18px;
}

.login_getcode_btn {
    margin-left: 12px;
    border-radius: 2px;
    background-color: #0171C5;
    min-width: 99px;
}

.login_code_item label::before {
    display: inline-block;
    margin-inline-end: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.login_pas_find {
    width: 230px;
    height: 275px;
    background: #F1F3F6;
    border-radius: 6px 6px 6px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 37px;
    cursor: pointer;
}

.login_pas_text {
    font-size: 20px;
    font-weight: 400;
    color: #666666;
    margin-top: 30px;
    margin-bottom: 25px;
}

.go_login {
    font-size: 16px;
    color: #0171C5;
    text-decoration: underline;
}

.home_search {
    align-items: flex-start;
    margin-bottom: 10px;
}

.home_search_icon {
    position: absolute;
    right: 10px;
    top: 8px;
    width: 16px;
    height: 16px;
}

.home_label_text {
    flex: none;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    width: 114px;
    text-align: right;
    /* margin-top: 10px; */
}

.home_label {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    padding: 3px 10px;
    border: 1px solid #E2E2E2;
    cursor: pointer;
    margin-right: 10px;
    min-width: 109px;
    text-align: center;
    margin-bottom: 10px;
}

.body {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}

.home_label_active {
    background-color: #0171C5;
    color: #fff;
}

.second_body {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* position: relative; */
    z-index: 1050;
    border-radius: 2px;
    box-shadow: none;
    transform-origin: 0;
    /* top:70px; */
}

.second_cont {
    width: 1200px;
    padding-top: 19px;
    align-items: flex-start;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}
.second_hidden{
    width: 100%;
    height: 550px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
}
.header_intel {
    position: static;
}

.head_none {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    margin-top: 68px;
}

.second_body{

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
}

.second_title {
    width: 274px;
    /* height: 100%; */
    margin-right: 25px;
    margin-bottom: 25px;
}

.second_title_color {
    height: 40px;
    border-bottom: 1px solid #E6EFFE;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 40px;
    overflow: hidden;
    cursor: pointer;
}

.second_child_title {
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
    color: #0071C4;
    line-height: 15px;
}

.home_cont {
    flex-wrap: wrap;
    margin-top: 20px;
}

.home_unit_card {
    width: 386px;
    height: 360px;
    border: 1px solid #CECECE;
    padding: 15px;
    margin-right: 21px;
    margin-bottom: 20px;
    position: relative;
}

.home_unit_card:nth-child(3n) {
    margin-right: 0;
}

.home_unit_tag {
    position: absolute;
    top: 0;
    right: 0;
}

.home_unit_tag_label {
    background-color: #FF7442;
    padding: 3px 14px;
    color: #fff;
    font-size: 14px;
}

.home_unit_title {
    margin-top: 16px;
    color: #333;
    font-size: 18px;
}

.home_unit_text {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 5px;
}

.home_line {
    width: 1px;
    height: 15px;
    background-color: #666;
    margin: 0 10px;
}

.home_btn {
    width: 95px;
    height: 36px;
    border-radius: 2px 2px 2px 2px;
    text-align: center;
    line-height: 36px;
    margin-right: 10px;
    cursor: pointer;
}

.home_unit_info {
    display: none;
    width: 356px;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 10px;
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
}

.home_unit_card:hover .home_unit_info {
    display: block;
}

.home_no_pass {
    border: 1px solid #F15151;
    font-size: 16px;
    font-weight: 400;
    color: #F84F4F;
}

.home_pass {
    font-size: 16px;
    font-weight: 400;
    color: #3FBF66;
    border: 1px solid #7FE9B1;
}

.home_learn_btn {
    width: 120px;
    height: 36px;
    background: #0071C4;
    border-radius: 2px 2px 2px 2px;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}

.home_btn_div {
    margin-top: 15px;
    justify-content: flex-end;
}

.home_card_page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.details_body {
    width: 1200px;
    margin: 0 auto;
    padding-top: 18px;
}

.details_left {
    width: 900px;
}

.details_title {
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    line-height: 50px;
    border-bottom: 1px solid #F2F2F2;
    position: relative;
}

.details_title::after,
.details_brief_title::after {
    content: '';
    width: 20px;
    height: 3px;
    background: #247ADE;
    position: absolute;
    bottom: 0;
    left: 0;
}

.detalis_cont {
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 11px;
}

.details_num {
    font-size: 14px;
    font-weight: 400;
    color: #0071C4;
    margin-right: 25px;
}

.details_num img {
    width: 16px;
    height: 11px;
    margin-right: 10px;
}

.details_brief {
    width: 100%;
    padding: 14px;
    border: 1px solid #F2F2F2;
    margin-top: 16px;
}

.details_brief_title {
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    height: 30px;
    border-bottom: 1px solid #F2F2F2;
    position: relative;
}

.details_brief_cont {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}

.unit_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -27.5px;
    margin-top: -27.5px;
    width: 45px;
    height: 45px;
}

.text_banner {
    width: 100%;
    height: 270px;
    background-color: #0171C5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.text_banner_cont {
    width: 1200px;
    padding-left: 99px;
    padding-bottom: 57px;
}

.test_cont {
    width: 1200px;
    margin: -57px auto 0 auto;
    background-color: #fff;
    box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0px 0px;
    padding: 18px 30px;
}

.home_nothing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_banner .ant-carousel .slick-dots {
    justify-content: flex-start;
}

.details_tag_label {
    font-size: 14px;
    font-weight: 400;
    color: #F84F4F;
    padding: 2px 5px;
    border: 1px solid #FBD0D0;
    margin-right: 10px;
    margin-top: 10px;
}

.home_tag_line {
    display: inline-block;
    width: 1px;
    height: 8px;
    background: #FFFFFF;
    opacity: 0.29;
    margin: 0 10px 0 5px;
}
.home_banner_2{
    width: 100%;
    height: 400px;
    background-size: 100% 100%;
    background-image: url(../img/banner2.png);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}
.banner_left{
    width: 571px;
    height: 323px;
    background: #0071C4;
    padding: 70px 30px 0 30px;
}
.banner_name{
    font-size: 36px;
    font-weight: 400;
    color: #FFFFFF;
}
.banner_info{
    font-size: 16px;
    font-weight: 400;
    color: #70AAD6;
    margin-top: 10px;
}
.banner_btn{
    width: 151px;
    height: 47px;
    background: #FFFFFF;
    font-size: 22px;
    font-weight: 400;
    color: #0071C4;
    justify-content: center;
    margin-top: 35px;
}
.ipa_cont{
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}
.ipa_ba{
    width: 649px;
    height: 310px;
    background-image: url(../img/ipa.png);
    background-size: 100% 100%;
    padding-top: 39px;
    padding-left: 28px;
}
.ipa_cont_title{
    font-size: 28px;
    font-weight: 400;
    color: #FFFFFF;
}
.ipa_cont_text{
    width: 582px;
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 24px;
    margin-top: 22px;
}
.ipa_cont_btn{
    width: 150px;
    height: 40px;
    background: #0071C4;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    margin-right: 25px;
    cursor: pointer;
}
.ipa_cont_register{
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: underline;
    cursor: pointer;
}
.ipa_right{
    width: 551px;
    height: 310px;
    background-image: url(../img/ipa9.jpg);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ipa_text{
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 15px;
}
.ipa_card{
    width: 290px;
    height: 153px;
    padding-top: 25px;
    padding-left: 20px;
    margin-right: 13px;
    margin-bottom: 13px;
    background-size: 100% 100%;
}
.ipa_card:nth-child(4n){
    margin-right: 0;
}
.ipa_card_cont{
    flex-wrap: wrap;
}
.ipa_card_title{
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 7px;
}
.ipa_card_text{
    width: 251px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}