.oem_body {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
}

.body_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.body_flex1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.oem_user_news {
    width: 596px;
    height: 258px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    padding: 10px 6px;
}

.oem_user_head {
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;
    padding: 0 14px 10px 14px;
    align-items: flex-start;
}

.oem_user_name {
    font-size: 22px;
    font-weight: 400;
    color: #333333;
}

.oem_user_email {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
}

.oem_user_certificate {
    width: 139px;
    height: 29px;
    background: #FCF3EB;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: 400;
    color: #FF7E00;
    padding-left: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.oem_semester {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}

.oem_semester_left {
    width: 360px;
    padding: 0 20px;
    border-right: 1px solid #EFEFEF;
}

.oem_semester_news {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    margin-top: 9px;
}

.oem_semester_task {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
}

.oem_semester_progress {
    width: 81px;
    height: 81px;
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oem_btn {
    width: 205px;
    height: 40px;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #DEE6F2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
}

.oem_btn_line {
    width: 0px;
    height: 16px;
    opacity: 1;
    border: 1px solid #DEE6F2;
    margin: 0 15px;
}

.oem_series_courses {
    width: 100%;
    margin-top: 22px;
}

.oem_series_courses_cont {
    width: 100%;
    height: 120px;
    cursor: pointer;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.oem_series_courses_name {
    font-size: 32px;
    font-weight: 400;
    color: #FFFFFF;
}

.oem_series_unit_unm {
    height: 30px;
    background: #F2F7FE;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: bold;
    color: #0071C4;
    padding: 5px 8px;
    margin-left: 11px;
}

.oem_series_courses_intro {
    width: 1146px;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 8px;
    text-align: center;
}

.oem_series_unit_card {
    width: 1200px;
    background-color: #fff;
    padding: 15px 30px 0 30px;
}

.oem_series_unit_index {
    width: 86px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 300;
    color: #3E6FD5;
    margin-right: 31px;
    /* background-image: url(../img/oem10.png); */
    background-size: 100% 100%;
}

.oem_series_unit_news {
    width: 530px;
    cursor: pointer;
}

.oem_series_unit_title {
    max-width: 328px;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.oem_series_unit_label {
    width: 49px;
    height: 24px;
    background: #D7EBF7;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #B9D6EC;
    font-size: 12px;
    font-weight: 400;
    color: #0071C4;
    text-align: center;
    line-height: 24px;
    margin: 0 7px;
}

.oem_series_unit_label1 {
    max-width: 105px;
    min-width: 60px;
    height: 24px;
    background: #FFF9F2;
    border-radius: 3px 3px 3px 3px;
    border: 1px solid #FFD9CC;
    font-size: 12px;
    font-weight: 400;
    color: #FF7442;
    text-align: center;
    line-height: 24px;
    padding: 0 5px;
    margin-right: 7px;
}

.oem_series_unit_intro {
    width: 520px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    margin-top: 4px;
    height: 32px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.text_hidden1 {
    height: 32px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.text_hidden2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    text-align: justify;
}

.oem_series_unit_line {
    width: 0px;
    height: 44px;
    opacity: 0.17;
    border: 1px solid #707070;
    margin: 0 40px 0 15px;
}

.oem_series_unit_text {
    width: 260px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 7px;
}

.oem_series_unit_btn {
    width: 129px;
    height: 32px;
    background: #0071C4;
    border-radius: 4px 4px 4px 4px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
}

.oem_series_unit_close {
    width: 100%;
    padding: 20px 0;
    font-size: 12px;
    font-weight: bold;
    color: #0071C4;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
}

.oem_search {
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-top: 10px;
    align-items: flex-start;
}

.oem_search span {
    margin-bottom: 10px;
    margin-top: 5px;
}

.oem_search_title {
    min-width: 120px;
    height: 34px;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #DEE6F2;
    padding: 0 8px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 34px;
    margin-right: 10px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;
}

.oem_search_title_active {
    background-color: #0071C4;
    color: #fff;
}

.oem_unit_card {
    width: 590px;
    height: 223px;
    background-color: #fff;
    margin-right: 20px;
    padding: 0 15px;
    margin-bottom: 50px;
    cursor: pointer;
}

.oem_unit_card:nth-child(2n) {
    margin-right: 0;
}

.oem_unit_img {
    width: 258px;
    height: 145px;
    margin-right: 20px;
    margin-top: -28px;
}

.oem_unit_title {
    width: 279px;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    padding-bottom: 11px;
    border-bottom: 1px solid #ECECEC;
}

.oem_unit_head {
    padding-bottom: 9px;
    border-bottom: 1px solid #ECECEC;
}

.oem_unit_intro {
    height: 39px;
    padding-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.oem_unit_btn {
    width: 90px;
    height: 30px;
    background: #0071C4;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: 400;
    color: #F8FBFF;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
}

.oem_details_bread {
    font-size: 12px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
}

.oem_details_bread span {
    color: #0071C4;
}

.oem_details_body {
    width: 1200px;
    margin: 0 auto;
    padding: 20px 0 30px 0;
}

.oem_details_left {
    width: 900px;
}

.oem_details_title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    padding-bottom: 12px;
    border-bottom: 1px solid #ECEEF2;
    position: relative;
}

.oem_details_title::after {
    content: '';
    width: 20px;
    height: 3px;
    background: #247ADE;
    border-radius: 30px 30px 30px 30px;
    position: absolute;
    left: 0;
    bottom: 0;
}

.oem_details_num {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    margin-right: 25px;
}

.oem_details_body .video-react.video-react-fluid {
    height: 100%;
}

.oem_details_test_btn {
    width: 118px;
    height: 35px;
    background: #0071C4;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: 400;
    color: #F8FBFF;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
}

.oem_details_video {
    width: 100%;
    height: 506px;
    position: relative;
}

.oem_question_btn {
    width: 128px;
    height: 40px;
    background: #0171C5;
    border-radius: 0px 30px 30px 0px;
    position: absolute;
    left: 0;
    top: 250px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    z-index: 9;
}

.oem_details_pdf {
    width: 100%;
    height: 900px;
    position: relative;
}

.oem_details_text {
    width: 100%;
}

.oem_details_intro {
    width: 100%;
    border: 1px solid #ECEEF2;
    border-radius: 2px;
    padding: 14px;
    margin-top: 14px;
}

.oem_details_intro_text {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
}

.oem_detail_right {
    width: 276px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 69px;
}

.oem_details_other {
    width: 100%;
    height: 506px;
    overflow: hidden;
    margin: 23px 0;
}

.oem_details_other_cont {
    width: 276px;
    height: 155px;
    margin-bottom: 21px;
    position: relative;
    background-size: 100% 100% !important;
    cursor: pointer;
}

.oem_details_other_text {
    width: 49px;
    height: 23px;
    background: #0071C4;
    border-radius: 0px 2px 0px 6px;
    text-align: center;
    line-height: 23px;
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
}

.oem_details_other_footer {
    width: 100%;
    height: 36px;
    background: #2C2F3A;
    border-radius: 0px 0px 2px 2px;
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    padding: 0 6px;
}

.oem_details_other_test {
    width: 64px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #743540;
    font-size: 13px;
    font-weight: 400;
    color: #FF3F3F;
    text-align: center;
    line-height: 26px;
}

.oem_details_other_test1 {
    width: 64px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #CECECE;
    font-size: 13px;
    font-weight: 400;
    color: #CECECE;
    text-align: center;
    line-height: 26px;
}

.oem_details_other_test2 {
    width: 64px;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #CEEEE2;
    font-size: 13px;
    font-weight: 400;
    color: #00AA57;
    text-align: center;
    line-height: 26px;
}

.text_hidden {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oem_details_test_score {
    width: 106px;
    height: 35px;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #CEEEE2;
    font-size: 18px;
    font-weight: 400;
    color: #00AA57;
    text-align: center;
    line-height: 35px;
    margin-right: 10px;
}

.oem_details_test_fail {
    border: 1px solid #FFC7C7;
    color: #FF3F3F;
}

.oem_nopass_btn {
    width: 90px;
    height: 30px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FFC7C7;
    color: #FF3F3F;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
}

.oem_pass_btn {
    width: 90px;
    height: 30px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #A6E1C4;
    color: #00AA57;
    margin-right: 10px;
    text-align: center;
    line-height: 30px;
}

.oem_details_body .video-react .video-react-big-play-button {
    width: 1.5em;
    border-radius: 100%;
}

.text_banner {
    width: 100%;
    height: 270px;
    background-color: #0071C4;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #4095D3;
}

.test_text {
    font-size: 20px;
    font-weight: 400;
    color: #C4DEF1;
}


.test_cont .ant-radio-group,
.test_cont .ant-checkbox-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.test_cont .ant-checkbox-group label {
    margin-right: 8px;
}

.test_time {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    padding-bottom: 9px;
    border-bottom: 1px solid #F2F2F2;
}

.test_footer {
    width: 100%;
    height: 116px;
    display: flex;
    align-items: center;
    border-top: 1px solid #F2F2F2;
    justify-content: center;
    background-color: #fff;
}

.test_footer button {
    width: 305px;
    height: 50px;
    background: #0071C4;
    border-radius: 4px 4px 4px 4px;
}

.test_body {
    margin: -57px auto 0 auto;
    width: 1200px;
}

.certificate_body {
    width: 800px;
    padding: 12px 20px;
    border-bottom: 1px solid #E2E2E2;
}

.certificate_img {
    width: 759px;
    height: 536px;
    background-image: url(../img/certificate.png);
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificate_img1 {
    width: 759px;
    height: 536px;
    /* background-image: url(../img/graduation.png); */
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certificate_cont {
    width: 600px;
    height: 600px;
}

.certificate_modal .ant-modal-body {
    padding: 0;
}

.certificate_footer {
    height: 77px;
    padding: 0 20px;
    justify-content: space-between;
}

.test_pass {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
}

.certificate_btn {
    width: 137px;
    height: 40px;
    background: #0171C5;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}

.certificate_time {
    /* width: 186px; */
    text-align: right;
    color: #0068B5;
    font-size: 14px;
    margin-top: 1px;
    /* margin-left: -4px; */
    margin-right: 34px;
    font-family: 'IntelClear_Hans_Rg';
}

.certificate_id {
    /* width: 186px; */
    text-align: right;
    color: #0068B5;
    font-size: 14px;
    /* margin-top: -3px; */
    /* margin-left: 14px; */
    margin-right: 50px;
    font-family: 'IntelClear_Hans_Rg';
}

.test_not_pass {
    height: 148px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid #E2E2E2;
}

.test_not_pass_text {
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 8px;
}

.test_not_pass_sore {
    font-size: 42px;
    font-weight: 400;
    color: #E41C1C;
}

.test_not_pass_btn {
    width: 110px;
    height: 40px;
    background: #0171C5;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
}

.test_not_pass_footer {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.certificate_name {
    width: 100%;
    font-size: 65px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    margin-top: 230px;
    text-align: center;
    font-family: 'IntelClear_Hans_Bd';
}

.certificate_course_name {
    width: 100%;
    height: 62px;
    font-size: 17px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    /* margin-top: 280px; */
    font-family: 'IntelClear_Hans_Rg';
}

.testpaper_ha {
    margin-top: 20px;
}

.testpaper_ha .ant-checkbox-wrapper+.ant-checkbox-wrapper {
    margin-left: 0;
}

.testpaper_name {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.testpaper_err {
    font-size: 16px;
    font-weight: 400;
    color: #DD0808;
    margin-right: 20px;
}

.oem_nothing {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oem_series_test {
    width: 129px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #FFC7C7;
    color: #FF3F3F;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    margin-top: 10px;
}

.oem_series_test1 {
    width: 129px;
    height: 32px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #A6E1C4;
    color: #00AA57;
    font-size: 16px;
    text-align: center;
    line-height: 32px;
    margin-top: 10px;
}

.oem_user_certificate1 {
    width: 124px;
    height: 29px;
    background: #ECF1F7;
    border-radius: 2px 2px 2px 2px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: center;
    line-height: 29px;
    margin-top: 10px;
}

.oem_body .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: #1890ff !important;
}

.oem_body .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #0071C4;
}

.oem_page {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oem_page .ant-pagination-item,
.oem_page .ant-pagination-prev .ant-pagination-item-link,
.oem_page .ant-pagination-next .ant-pagination-item-link {
    background-color: #F0F2F5;
    border: none;
}

.oem_page .ant-pagination-item-active {
    background-color: #0171C5;
    border: none;

}

.oem_page .ant-pagination-item-active a {
    color: #fff;

}

.oem_question .ant-modal-footer div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.oem_question .ant-modal-footer div button {
    width: 110px;
}

.oem_question .ant-modal-footer div button:first-child {
    background-color: #E4EFF7;
    color: #0171C5;
}

.oem_question .ant-modal-footer div button:nth-child(2) {
    background-color: #0171C5;
    color: #fff;
}

.details_left .ant-spin-nested-loading {
    background-color: rgba(0, 0, 0, 0.1);
}

/* .ant-statistic-content-value {
    color: #0071C4;
    font-size: 30px;
} */

.oem_play {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
}

.oem_details_time .ant-statistic-content-value,
.oem_details_time span {
    font-size: 20px;
    color: #0071C4;
}

.newprodcut_video .video-react.video-react-fluid {
    height: 346px;
}

.related_item_title1 {
    width: 120px;
    /* height: 66px; */
    /* margin-top: 13px; */
    margin-left: 10px;
    font-size: 14px;
    color: #333;
    word-break: break-all;
    line-height: 22px;
}

.product_two_line {
    width: 0px;
    height: 9px;
    opacity: 0.33;
    border: 1px solid #707070;
    margin-right: 10px;
}

.test_from_1 {
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    height: 51px;
    width: 560px;
    margin-bottom: 20px !important;
    padding-left: 10px !important;
}

.test_from_1 span:nth-child(2) {
    padding-top: 5px;
}