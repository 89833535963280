body {
    margin: 0;
}
@font-face {
    font-family: 'IntelClear_Hans_Bd';
    src: url('../../public/font/IntelClear_Hans_Bd.ttf')
}
@font-face {
    font-family: 'IntelClear_Hans_Rg';
    src: url('../../public/font/IntelClear_Hans_Rg.ttf')
}

.ant-layout-header {
    height: 70px !important;
    padding: 0px !important;
}

.ant-layout-header .ant-menu {
    width: 100%;
    background-color: #0071C4 !important;
}

.ant-menu-horizontal {
    border: none !important;
}

.ant-layout-footer {
    background: #e7e7e7 !important;
    padding: 0px !important;
    width: 100% !important;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item,
.gba_menu .ant-menu-title-content {
    color: #fff;
    font-size: 18px;
}

.gba_menu {
    font-size: 18px;
}

.gba_menu li {
    height: 67px;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after {
    border-bottom-color: #fff !important;
}

.gba_menu li:nth-last-child(2),
.gba_menu li:nth-last-child(2) div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    /* margin-right: 20px; */
    padding-right: 50px !important;
}

.gba_menu {
    z-index: 999;
}

.gba_menu li:nth-last-child(2)::after {
    border: none !important;
}

#footerss {
    height: 400px;
    width: 100%;
    min-width: 1200px;
}

#footerss iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.ant-layout-footer {
    padding: 0;
}

/* 全局提示 */
/* 全局提示 message 多个变单个 */
.ant-message-notice {
    padding: 8px;
    text-align: center;
    position: absolute;
    left: 50%;
    margin-left: -259px;
}

.ant-message-notice:first-child {
    margin-top: 0
}

.ant-message .ant-message-notice .ant-message-notice-content {
    padding: 0;
    border: none;
}

.ant-btn-ghost {
    border: 1px solid #0071C5;
    border-radius: 0px;
    color: #0071C5;
    width: 110px;
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-error,
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-info,
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-warning {
    width: 518px;
    min-height: 48px;
    line-height: normal;
    padding: 15px 0;
    color: #fe7443;
    background-color: #fce1d3;
    border: none;
    text-align: center;
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-success {
    width: 518px;
    min-height: 48px;
    line-height: normal;
    padding: 15px 0;
    background-color: #e3fcd3;
    border: none;
    text-align: center;
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-success span {
    color: #87d068;
}

.ant-message .ant-message-notice .ant-message-notice-content .ant-message-error span,
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-warning span,
.ant-message .ant-message-notice .ant-message-notice-content .ant-message-success span {
    height: auto;
    line-height: normal;
}

/* 全局提示结束 */
/* 分页 */
.ant-pagination-item a {
    background: #F0F2F5;
    border-radius: 2px;
    border-color: #f0f2f5;
    color: #606266;
    height: 100%;
}

.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
    border-color: #0171C5;
    background: #0171C5;
}

.ant-pagination-item-active {
    color: white;
    background: #0171C5;

}


.ant-pagination-item-active a {
    background: #0171C5;
    border-color: #0171C5;
    color: white !important;
}

.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
    background: #0171C5;
    color: white;

}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
    background-color: #F0F2F5 !important;
    border-radius: 2px;
    border-color: #f0f2f5 !important;
}

.ant-image-preview-switch-right,
.ant-image-preview-switch-left {
    background-color: #0171c5;
}


.video-react .video-react-big-play-button {
    width: 1.5em;
    border-radius: 100%;
}

p{
    margin: 0 !important;
}

.footers{
    width: 100%;
    height: 410px;
    background-color: #0068B5;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 75px;
}
.footer_cont{
    width: 1200px;
}
.footer_right{
    width: 745px;
}
.footer_right_head{
    width: 100%;
    padding-bottom: 22px;
    border-bottom: 1px solid #3386C4;
}
.footer_right_head a{
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
}

.footer_right_cont,.footer_right_head,.footer_cont,.footer_right_footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.footer_right_cont{
    padding: 30px 0;
}
.footer_phone{
    font-size: 32px;
    font-weight: 400;
    color: #FFFFFF;
}
.footer_news{
    font-size: 16px;
    font-weight: 400;
    color: #77AED8;
    margin-top: 10px;
}
.footer_img_back{
    width: 100px;
    height: 100px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer_wechat{
    font-size: 14px;
    font-weight: 400;
    color: #EEF5FA;
    margin-left: 22px;
}
.footer_right_footer{
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 41px;
}
.footer_right_footer a{
    color: #fff;
}
.footer_right_footer a:hover{
    color: #fff;
    text-decoration: underline;
}